import React, {useRef, useState, useEffect} from "react";
import close from "../images/close.png"
import record from "../images/record-img.png"
import stopRecordingImg from "../images/stop-recording.png"

export const VideoRecorder = ({isMyStory, reelVideo, setIsReelOpen, getMyStoryBlob}) => {
  const videoRef = useRef(null);
  const recordedVideoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const isSafari = !window.navigator.userAgent.includes('Chrome')

  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          mediaRecorderRef.current = new MediaRecorder(stream, {
            mimeType: isSafari ? 'video/mp4' : 'video/webm'
          });

          let chunks = [];
          mediaRecorderRef.current.ondataavailable = (event) => {
            if (event.data.size > 0) {
              chunks.push(event.data);
            }
          };

          mediaRecorderRef.current.onstop = () => {
            const blob = new Blob(chunks, { type: isSafari ? 'video/mp4' : 'video/webm' });
            console.log(mediaRecorderRef.current)
            chunks = [];
            setRecordedVideo(URL.createObjectURL(blob));
          };
        }
      } catch (err) {
        console.error('Error accessing the camera: ', err);
      }
    };

    startCamera();
  }, []);


  const startRecording = () => {
    setIsRecording(true);
    mediaRecorderRef.current.start();
  };

  const stopRecording = () => {
    setIsRecording(false);
    mediaRecorderRef.current.stop();
  };
  const onCloseButton = () => {
    setIsReelOpen(false)
    setRecordedVideo(null)
  }
  const onPublishButton = () => {
    setIsReelOpen(false)
    getMyStoryBlob(recordedVideo)
  }

  return (
    <div className="recorder-container">
        <button className="close-button" onClick={onCloseButton}>
            <img src={close} alt='close' />
        </button>
      <div className="mirror">
       {!recordedVideo && isMyStory && <video ref={videoRef} autoPlay playsInline className="mirror-video"></video>}
      </div>
      <div>
        {isMyStory && recordedVideo && 
          <button className="button" onClick={onPublishButton}>Publish</button>
        }
        {!isRecording && !recordedVideo && isMyStory && (
          <button className="record-button" onClick={startRecording}>
            <img src={record} alt="record" />
          </button>
        )} 
        {isRecording && !recordedVideo && isMyStory && (
          <button className="record-button" onClick={stopRecording}>
            <img src={stopRecordingImg} alt="stop-record" />
          </button>
        )}
      </div>
      {recordedVideo && isMyStory && (
        <div>
          <h3>Recorded Video:</h3>
          <video
            ref={recordedVideoRef}
            src={recordedVideo}
            autoPlay
            playsInline
            className="recorded-video"
          ></video>
        </div>
      )}
      {!isMyStory && (
        <div>
            <video src={reelVideo} autoPlay playsInline className="recorded-video" />
        </div>
      )}
    </div>
  );
}