import React, { useState } from 'react';
import background from './images/bg_3.png'
import plus from './images/plus.png'
import brownDog from './images/man_and_dog.jpg'
import dalmatDog from './images/dog_dalmat.jpg'
import womanAndDog from './images/women_and_dog.jpg'
import myDog from './images/my_dog.jpg'
import video1 from "../src/videos/video_1.MP4"
import video2 from "../src/videos/video_2.mov"
import './App.css';
import { VideoRecorder } from './components/videoRecorder';

const REELS_USERS = [
  {image: womanAndDog, name: 'Name', forFriend: true, video: video1},
  {image: dalmatDog, name: 'Name', video: video2},
  {image: brownDog, name: 'Name'},
]

function App() {
  const [isReelOpen, setIsReelOpen] = useState(false)
  const [isMyStory, setIsMyStory] = useState(false)
  const [myStoryBlob, setMyStoryBlob] = useState()
  const [reelVideo, setReelVideo] = useState(null)

  const onMyStoryClick = () => {
    setIsReelOpen(true)
    setIsMyStory(true)
  }

  const onReelClick = (video) => {
    setIsReelOpen(true)
    setIsMyStory(false)
    setReelVideo(video)
  }
  const getMyStoryBlob = (blob) => {
    setMyStoryBlob(blob)
  }

  return (
    <div className='App'>
      {!isReelOpen ? (
        <>
          <div className='reels-container'>
            <div className='reel-item-me' onClick={onMyStoryClick}>
              <img className='plus-image' src={plus} alt='plus' />
              <img src={myDog} alt='my-dog' className='reel-image' />
              <p className='reel-name'>My bite</p>
            </div>
            <div className='divider' />
            {myStoryBlob && (
               <div className='reel-item' onClick={() => onReelClick(myStoryBlob)}>
               <img
                 src={myDog}
                 alt='reelImage'
                 className='reel-image'
               />
               <p className='reel-name'>My Name</p>
             </div>
            )}
            {REELS_USERS.map((reel, index) => (
              <div className='reel-item' key={index} onClick={() => onReelClick(reel.video || video1)}>
                <img
                  src={reel.image}
                  alt='reelImage'
                  className='reel-image'
                  style={{ borderColor: reel.forFriend ? '#35B43F' : '#F8B900' }}
                />
                <p className='reel-name'>{reel.name}</p>
              </div>
            ))}
          </div>
          <div style={{ width: '100%', height: '100%' }}>
            <img src={background} width='100%' height='100%' alt='background' />
          </div>
        </>
      ) : (
        <VideoRecorder getMyStoryBlob={getMyStoryBlob} 
          isMyStory={isMyStory} 
          reelVideo={reelVideo} 
          setIsReelOpen={setIsReelOpen}
        />
      )}
    </div>
  );
  
}

export default App;
